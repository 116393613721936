.our-team {
    position: relative;
    width: 100%;
    background-color: #EFEAE6;
    overflow: hidden;

    &.is-visible {
        .overview {
            img {
                transform: scale(1) translateX(0);
                opacity: 1;

                @media (min-width: 1024px) {
                    transform: scale(1) translateY(-50%) translateX(0);
                }
            }

            .content-container {
                transform: translateX(0px);
                opacity: 1;
            }
        }
    }

    @media (min-width: 1024px) {
        padding-bottom: 154px;
    }


    .overview {
        display: flex;
        flex-direction: column;
        background-color: #6B123C;
        box-sizing: border-box;
        position: relative;


        @media (min-width: 1024px) {
            flex-direction: row;
            justify-content: flex-end;
            padding-top: 112px;
            padding-bottom: 112px;
            padding-right: 40px;
            width: calc(100% - 40px);
            float: right;

        }

        @media (min-width:1200px) {
            width: calc(100% - 90px);
            padding-right: 90px;
        }

        @media (min-width: 1400px) {
            width: calc(100% - (100% - 1200px)/2);
            padding-right: calc((100% - 1200px)/2);
        }

        @media (min-width: 1900px) {
            width: calc(100% - (100% - 1500px)/2);
            padding-right: calc((100% - 1500px)/2);
        }

        img {
            width: 100%;
            height: 100vw;
            object-fit: cover;

            transform: scale(0.8);
            opacity: 0;
            transition-property: opacity, transform;
            transition-duration: 0.7s;
            transition-delay: 0.1s;

            @media (min-width: 1024px) {
                position: absolute;
                width: 700px;
                height: 588px;
                left: -30px;
                top: 50%;
                transform: scale(1) translateY(-50%) translateX(-50px);
            }

            @media (min-width: 1400px) {
                left: -100px;
            }
        }

        .content-container {
            padding: 72px 32px;
            display: flex;
            flex-direction: column;
            gap: 24px;

            transform: translateX(0px);
            opacity: 0;
            transition-property: opacity, transform;
            transition-duration: 0.7s;
            transition-delay: 0.1s;

            @media (min-width: 1024px) {
                max-width: 505px;
                padding: 0px;
                align-items: flex-end;
                text-align: right;

                transform: translateX(20px);
            }

            h3 {
                font-family: Setimo-Bold;
                color: #F47F04;
                font-size: 15px;
                text-transform: uppercase;
                letter-spacing: 5px;
            }

            h2 {
                font-family: Setimo-Regular;
                color: #fff;
                font-size: 26px;
                line-height: 38px;
                text-transform: uppercase;

                @media (min-width: 1024px) {
                    font-size: 40px;
                    line-height: 58px;
                }
            }

            .text {
                display: flex;
                flex-direction: column;
                gap: 24px;

                p {
                    font-family: Georgia;
                    color: #fff;
                    font-size: 16px;
                    line-height: 28px;
                    opacity: 0.7;

                    @media (min-width: 1024px) {
                        font-size: 18px;
                        line-height: 30px;
                    }
                }
            }

            .btns {
                display: flex;
                flex-direction: column;
                gap: 32px;
                margin-top: 24px;

                @media (min-width: 1024px) {
                    flex-direction: row;
                }

                .our-team-btn {
                    flex: 1;
                    width: 100%;
                    border: 2px solid #F47F04;

                    span {
                        color: #F47F04;
                    }


                    @media (min-width: 768px) {

                        &:after {
                            background-color: #F47F04;
                        }

                        &:hover {
                            span {
                                color: #fff;
                            }
                        }
                    }

                }

                .divider {
                    width: 100%;
                    height: 1px;
                    margin: 0 auto;
                    background-color: #fff;
                    opacity: 0.2;

                    @media (min-width: 1024px) {
                        display: none;
                    }
                }
            }
        }
    }
}