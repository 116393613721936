.awards {
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;
    padding: 56px 32px;
    background-color: #EFEAE6;
    overflow-x: hidden;

    opacity: 0;
    transition-property: opacity;
    transition-duration: 0.7s;
    transition-delay: 0.2s;

    &.is-visible {
        opacity: 1;
    }

    @media (min-width:1024px) {
        padding-left: 40px;
        padding-right: 40px;
    }

    @media (min-width:1200px) {
        padding-left: 90px;
        padding-right: 90px;
    }

    @media (min-width: 1400px) {
        padding-left: calc((100% - 1200px) / 2);
        padding-right: calc((100% - 1200px) / 2);
    }

    @media (min-width: 1900px) {
        padding-left: calc((100% - 1500px) / 2);
        padding-right: calc((100% - 1500px) / 2);
    }

    h3 {
        font-family: Setimo-Bold;
        color: #6B123C;
        font-size: 15px;
        line-height: 20px;
        text-transform: uppercase;
        letter-spacing: 5px;
        text-align: center;
    }



    .main-swiper {
        width: 100%;
        height: 186px;
        position: relative;
        display: flex;
        align-items: center;
        gap: 8px;

        @media (min-width: 768px) {
            height: 152px;
        }

        .swiper-btn {
            position: relative;
            width: 18px;
            height: 38px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            z-index: 1;

            @media (min-width: 768px) {
                position: absolute;
            }

            &.swiper-button-disabled {
                opacity: 0.5;
                cursor: default;
            }

            &#prev {
                left: -10px;

                @media (min-width: 768px) {
                    left: -40px;
                }
            }

            &#next {
                right: -10px;


                @media (min-width: 768px) {
                    right: -40px;
                }
            }

            svg {
                width: 100%;
                height: 100%;
            }
        }

        .swiper {
            width: 100%;
            height: 100%;
            overflow: hidden;
            user-select: none;

            .swiper-container {
                height: 100%;

                .award-item {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;

                    img {
                        width: auto;
                        height: 100%;

                    }
                }
            }
        }
    }

    .pagination {
        @media (min-width: 768px) {
            margin-top: 40px;
        }

        .per-slide {
            display: flex;
            align-items: center;
            gap: 16px;

            @media (min-width: 768px) {
                display: none;
            }

            .current-page span,
            .total {
                font-family: Setimo-Bold;
                color: #6B123C;
                font-size: 13px;
                text-transform: uppercase;
            }

            .divider {
                width: 2px;
                height: 28px;
                background-color: #6B123C;
                transform: rotate(25deg);
            }
        }

        .per-view {
            display: flex;
            align-items: center;
            gap: 20px;

            @media (max-width: 767px) {
                display: none;
            }

            .swiper-pagination-bullet {
                width: 6px !important;
                height: 6px !important;
                border-radius: 50% !important;
                background-color: #6B123C !important;
                cursor: pointer !important;
                border: 2px solid #6B123C !important;
                opacity: 1 !important;

                &.swiper-pagination-bullet-active {
                    background-color: transparent !important;
                }
            }
        }
    }

    .award-modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 100;
        transition: all 0.3s;
        pointer-events: none;
        opacity: 0;

        &.open {
            pointer-events: all;
            opacity: 1;

            .modal-container {
                top: 50%;
            }
        }

        .bg {
            width: 100%;
            height: 100%;
            background-color: rgba(#000, 0.8);
        }

        .swiper-btn {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 18px;
            height: 38px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            z-index: 1;

            @media (min-width: 768px) {
                position: absolute;
            }

            &.swiper-button-disabled {
                opacity: 0.5;
                cursor: default;
            }

            &#prev {
                left: 10px;

                @media (min-width: 768px) {
                    left: 40px;
                }
            }

            &#next {
                right: 10px;

                @media (min-width: 768px) {
                    right: 40px;
                }
            }

            svg {
                width: 100%;
                height: 100%;
            }
        }

        .modal-container {
            position: absolute;
            top: 55%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: calc(100% - 64px);
            background-color: #fff;
            transition: all 0.3s;


            @media (min-width: 768px) {
                width: 80%;
            }

            @media (min-width: 1024px) {
                width: 60%;

            }

            @media (min-width: 1366px) {
                width: 750px;
            }

            .close-btn {
                width: 32px;
                height: 32px;
                position: absolute;
                top: 24px;
                right: 24px;
                cursor: pointer;
                z-index: 100;

                svg {
                    width: 100%;
                    height: 100%;
                }
            }

            .swiper {
                width: 100%;
                height: 100%;

                .award-slide {
                    width: 100%;
                    height: 100%;
                    position: relative;
                    padding-bottom: 32px;

                    .row {
                        background-color: #fff;
                        width: 100%;
                        padding: 32px;
                        box-sizing: border-box;

                        h5 {
                            font-family: Setimo-Bold;
                            font-size: 18px;
                            color: #4a4a4a;
                        }
                    }

                    .content {
                        width: 100%;
                        font-family: Georgia;
                        font-size: 16px;
                        line-height: 25px;
                        color: #4a4a4a;
                        padding: 0px 32px;
                        max-height: 500px;
                        overflow-y: auto;
                        box-sizing: border-box;
                    }

                    p {
                        font-family: Georgia;
                        font-size: 16px;
                        line-height: 25px;
                        color: #4a4a4a;
                        margin-bottom: 24px;
                    }

                    a {
                        font-family: Georgia;
                        font-size: 16px;
                        line-height: 25px;
                        color: #6B123C;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}