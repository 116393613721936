.mainbanner {
    display: block;

    picture {
        display: block;
    }

    &--static {
        margin-bottom: 20px;

        @media (min-width: 768px) {
            margin-bottom: 60px;
        }
    }

    &:not(.slick-slider) {
        .mainbanner__item {
            &:nth-child(n+2) {
                display: none;
            }
        }
    }

    &__item {
        position: relative;

        &.noimage {
            background: #a59682;
            background-image: linear-gradient(rgba(20, 23, 32, 0.5), #a59682);
            height: 482px;

            @media (min-width: 768px) {
                height: 772px;
            }
        }
    }

    &__title {
        position: absolute;
        text-transform: uppercase;
        font-family: Setimo-Regular;
        font-size: 50px;
        line-height: 1;
        font-weight: normal;
        color: #fff;
        padding-right: 20px;
        left: 0;
        top: 52%;
        transform: translateY(-50%);

        a {
            outline: none;
            color: #fff;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        &--static {
            @media (max-width: 767px) {
                font-size: 50px;
            }
        }

        &--contato {
            font-weight: bold;

            @media (max-width: 767px) {
                font-size: 60px;
            }
        }

        &--static {
            @media (max-width: 767px) {
                font-size: 50px;
            }
        }

        @media (min-width:360px) {
            font-size: 40px;
        }

        @media (min-width:768px) {
            top: 55%;
            width: 90%;
            font-size: 80px;
        }

        @media (min-width:1024px) {
            font-size: 70px;
        }

        @media (min-width:1200px) {
            font-size: 80px;
        }

        @media (min-width:1600px) {
            font-size: 100px;
        }

        @media (min-width:1900px) {
            width: 1100px;
        }

        @media (min-width:3000px) {
            width: 58%;
        }
    }

    &__title-profile {
        position: absolute;
        text-transform: uppercase;
        font-family: Setimo-Regular;
        font-size: 49px;
        line-height: 1;
        font-weight: normal;
        color: #fff;
        left: 0;
        bottom: 2%;

        @media (max-width:768px) {
            text-shadow: black 0.1em 0.1em 0.8em;
        }

        @media (min-width:360px) {
            font-size: 40px;
        }

        @media (min-width:768px) {
            top: 55%;
            width: 90%;
            font-size: 80px;
        }

        @media (min-width:1024px) {
            width: 73%;
            font-size: 70px;
        }

        @media (min-width:1200px) {
            font-size: 80px;
        }

        @media (min-width:1600px) {
            font-size: 100px;
        }

        .title-profile {
            position: relative;
        }
    }

    &__profilesubtitle {
        position: absolute;
        text-transform: none;
        font-family: Georgia, serif;
        font-size: 26px;
        font-style: italic;
        color: #fff;
        box-sizing: border-box;
        line-height: 30px;
        display: none;
        padding-top: 0.5em;
        left: 0;

        @media (max-width:768px) {
            text-shadow: black 0.1em 0.1em 0.6em;
        }

        @media (min-width:768px) {
            display: block;
        }

        @media (min-width:1024px) {
            font-size: 30px;
            width: 65%;
            line-height: 35px;
        }
    }

    &__media {
        display: block;
    }

    &__image {
        width: 100vw;
        max-width: 100%;
        height: 90vh;
        object-fit: cover;
        display: block;

        @media (min-width: 768px) {
            height: 100vh;
        }

        .ie & {
            width: 100%;
            height: auto;
        }
    }

    &__image-profile {
        width: 100vw;
        max-width: 100%;
        height: 482px;
        object-fit: cover;
        background-size: cover;
        display: block;
        object-position: left;

        @media (min-width: 768px) {
            height: 772px;
            object-position: right;
        }
    }
}

.internal-banner {
    width: 100%;
    height: 80vh;
    position: relative;
    display: flex;
    align-items: center;

    @media (min-width: 768px) {
        height: 100vh;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        user-select: none;
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
    }

    .content {
        width: 100%;
        position: relative;
        z-index: 2;

        @media (max-width: 768px) {
            max-width: 90%;
        }


        h1 {
            text-transform: uppercase;
            font-family: Setimo-Regular;
            font-size: 44px;
            line-height: 1;
            color: #fff;

            @media (min-width: 768px) {
                font-size: 70px;
                line-height: 1.33;
            }

            @media (min-width: 1600px) {
                font-size: 90px;
            }
        }

        p {
            font-family: Georgia, serif;
            font-size: 26px;
            font-style: italic;
            color: #fff;
            line-height: 30px;

            @media (min-width: 768px) {
                font-size: 30px;
                line-height: 35px;
            }
        }
    }

    &.profile-banner {
        align-items: flex-end;

        @media (min-width: 768px) {
            height: 80vh;
        }

        .content {
            padding-bottom: 24px;

            @media (min-width: 768px) {
                padding-bottom: 90px;
                max-width: 1100px;
            }
        }

        h1 {
            font-size: 40px;
            line-height: 1;

            @media (min-width: 768px) {
                font-size: 70px;
            }

            @media (min-width: 1600px) {
                font-size: 100px;
            }
        }

        p {
            margin-top: 16px;

            @media (max-width: 768px) {
                display: none !important;
            }
        }

    }

    &.noimage {
        background: #a59682;
        background-image: linear-gradient(rgba(20, 23, 32, 0.5), #a59682);
        height: 480px !important;

        @media (min-width: 768px) {
            height: 770px !important;
        }
    }
}