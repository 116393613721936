#header {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    box-sizing: border-box;
    border-top: 5px solid #6b123c;
    overflow: hidden;

    @media (min-width: 768px) {
        padding-top: 52px;
    }

    @media (min-width:1024px) {
        padding-left: 40px;
        padding-right: 40px;
    }

    @media (min-width:1200px) {
        padding-left: 90px;
        padding-right: 90px;
    }

    @media (min-width: 1400px) {
        padding-left: calc((100% - 1200px) / 2);
        padding-right: calc((100% - 1200px) / 2);
    }

    @media (min-width: 1900px) {
        padding-left: calc((100% - 1500px) / 2);
        padding-right: calc((100% - 1500px) / 2);
    }

    .header-logo {
        display: block;
        height: 44px;

        img {
            height: 100%;
        }

        @media (min-width: 1024px) {
            height: 58px;
        }
    }

    .language-selector {
        display: flex;
        gap: 16px;

        @media (min-width: 768px) {
            gap: 10px;
        }

        .language-btn {
            display: block;
            width: 34px;
            height: 28px;
            cursor: pointer;

            @media (min-width: 768px) {
                width: 28px;
                height: 20px;
            }

            @media (min-width: 1600px) {
                width: 30px;
                height: 22px;
            }

            path {
                fill: rgba(255, 255, 255, 0.5);
                transition: fill 0.2s;
            }

            &.active {
                path {
                    fill: #fff;
                }
            }

            &:not(.active) {
                &:hover {
                    path {
                        fill: rgba(255, 255, 255, 0.8);
                    }
                }
            }

            svg {
                width: 100%;
                height: 100%;
            }
        }
    }

    .desk-nav {
        display: none;
        flex-direction: column;
        gap: 20px;
        align-items: flex-end;

        @media (min-width: 1200px) {
            display: flex;
        }

        .item-list {
            display: flex;
            align-items: flex-start;
            gap: 20px;
            list-style: none;
            padding: 0;
            margin: 0;

            .item {

                a,
                span {
                    color: #fff;
                    text-decoration: none;
                    font-family: Setimo-Regular;
                    font-size: 13px;
                    text-transform: uppercase;
                    letter-spacing: 0.1em;
                    cursor: pointer;

                    @media (min-width: 1900px) {
                        font-size: 15px;
                    }
                }

                >a {
                    transition: color 0.2s;

                    &:hover {
                        opacity: .7;
                    }
                }

                .item-with-submenu {
                    position: relative;
                    // padding-bottom: 20px;

                    &:hover {
                        span {
                            opacity: 0.7;
                        }

                        .submenu {
                            display: flex;
                        }
                    }

                    .submenu {
                        display: none;
                        position: absolute;
                        top: 0px;
                        left: -12px;
                        width: 160px;
                        padding-top: 32px;
                        background-color: transparent;
                        border-radius: 8px;
                        flex-direction: column;
                        gap: 4px;

                        a {
                            padding: 16px;
                            display: block;
                            border-radius: 4px;
                            color: #fff;
                            font-weight: 500;
                            background-color: #f47f04;
                            transition: all 0.2s;

                            &:hover {
                                background-color: #fff;
                                color: #f47f04;
                            }
                        }
                    }
                }


            }
        }
    }

    .mob-nav {
        @media (min-width: 1200px) {
            display: none;
        }

        position: relative;
        z-index: 1000;

        .mob-nav-btn {
            position: relative;
            width: 32px;
            height: 32px;
            cursor: pointer;
            // background-color: #f47f04;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            gap: 7px;
            z-index: 10002;


            div {
                background-color: #fff;
                height: 2px;
                border-radius: 2px;
                transition: all cubic-bezier(0.25, 0.1, 0.28, 1.54) 0.32s;

                &:nth-of-type(1),
                &:nth-of-type(2) {
                    width: 24px;
                }

                &:nth-of-type(3) {
                    width: 16px;
                }
            }
        }

        .mob-nav-container {
            z-index: 1001;
            width: 100vw;
            height: 100vh;
            position: fixed;
            top: 0px;
            left: 0px;
            background-color: #6B123C;
            display: flex;
            justify-content: center;
            flex-direction: column;
            pointer-events: none;
            opacity: 0;
            transition: opacity .2s linear;

            .language-selector {
                position: absolute;
                top: 46px;
                left: 32px;
            }

            .item-list {
                position: relative;
                left: 32px;
                display: flex;
                flex-direction: column;
                gap: 28px;
                list-style: none;
                width: calc(100% - 64px);

                .item {

                    span {
                        color: #fff;
                        text-decoration: none;
                        font-family: Setimo-Regular;
                        font-size: 24px;
                        font-weight: 500;
                        text-transform: uppercase;
                        letter-spacing: 0.1em;
                    }

                    .submenu {
                        display: flex;
                        flex-direction: column;
                        gap: 28px;
                    }
                }
            }
        }

        &.open {
            .mob-nav-btn {
                div {
                    &:nth-of-type(1) {
                        width: 40px;
                        height: 3px;
                        transform: rotate(45deg) translate(10px, 3px);
                    }

                    &:nth-of-type(2) {
                        width: 0;
                        opacity: 0;
                    }

                    &:nth-of-type(3) {
                        width: 40px;
                        height: 3px;
                        transform: rotate(-45deg) translate(10px, -3px);
                    }

                }
            }

            .mob-nav-container {
                opacity: 1;
                pointer-events: all;
            }
        }
    }
}