.about-us {
    position: relative;
    width: 100%;
    background-color: #EFEAE6;
    overflow: hidden;

    &.is-visible {
        .overview {
            img {
                transform: scale(1) translateX(0);
                opacity: 1;

                @media (min-width: 1024px) {
                    transform: scale(1) translateY(-50%) translateX(0);
                }
            }

            .content-container {
                transform: translateX(0px);
                opacity: 1;
            }
        }

        .pillars {
            transform: translateY(0px);
            opacity: 1;
        }
    }

    .overview {
        display: flex;
        flex-direction: column;
        background-color: #6B123C;
        box-sizing: border-box;
        position: relative;

        @media (min-width: 1024px) {
            flex-direction: row-reverse;
            justify-content: flex-end;
            padding-top: 112px;
            padding-bottom: 112px;
            padding-left: 40px;
            width: calc(100% - 40px);

        }

        @media (min-width:1200px) {
            width: calc(100% - 90px);
            padding-left: 90px;
        }

        @media (min-width: 1400px) {
            width: calc(100% - (100% - 1200px)/2);
            padding-left: calc((100% - 1200px)/2);
        }

        @media (min-width: 1900px) {
            width: calc(100% - (100% - 1500px)/2);
            padding-left: calc((100% - 1500px)/2);
        }

        img {
            width: 100%;
            height: 100vw;
            object-fit: cover;

            transform: scale(0.8);
            opacity: 0;
            transition-property: opacity, transform;
            transition-duration: 0.7s;
            transition-delay: 0.1s;

            @media (min-width: 1024px) {
                position: absolute;
                width: 700px;
                height: 588px;
                right: -30px;
                top: 50%;
                transform: scale(1) translateY(-50%) translateX(50px);
            }

            @media (min-width: 1400px) {
                right: -100px;
            }


        }

        .content-container {
            padding: 72px 32px;
            display: flex;
            flex-direction: column;
            gap: 24px;

            transform: translateX(0px);
            opacity: 0;
            transition-property: opacity, transform;
            transition-duration: 0.7s;
            transition-delay: 0.1s;

            @media (min-width: 1024px) {
                max-width: 485px;
                padding: 0px;

                transform: translateX(-20px);
            }

            @media (min-width: 1200px) {
                max-width: 540px;
            }

            h3 {
                font-family: Setimo-Bold;
                color: #F47F04;
                font-size: 15px;
                text-transform: uppercase;
                letter-spacing: 5px;
            }

            h2 {
                font-family: Setimo-Regular;
                color: #fff;
                font-size: 26px;
                line-height: 38px;
                text-transform: uppercase;

                @media (min-width: 1024px) {
                    font-size: 40px;
                    line-height: 58px;
                }
            }

            .text {
                display: flex;
                flex-direction: column;
                gap: 24px;

                p {
                    font-family: Georgia;
                    color: #fff;
                    font-size: 16px;
                    line-height: 28px;
                    opacity: 0.7;

                    // @media (min-width: 1024px) {
                    //     font-size: 18px;
                    //     line-height: 30px;
                    // }
                }
            }

            .btns {
                display: flex;
                flex-direction: column;
                gap: 32px;
                margin-top: 24px;

                @media (min-width: 1024px) {
                    flex-direction: row;
                }

                .about-us-btn {
                    flex: 1;
                    width: 100%;
                    border: 2px solid #F47F04;

                    span {
                        color: #F47F04;
                    }


                    @media (min-width: 768px) {

                        &:after {
                            background-color: #F47F04;
                        }

                        &:hover {
                            span {
                                color: #fff;
                            }
                        }
                    }


                }

                .divider {
                    width: 100%;
                    height: 1px;
                    margin: 0 auto;
                    background-color: #fff;
                    opacity: 0.2;

                    @media (min-width: 1024px) {
                        display: none;
                    }
                }
            }
        }
    }

    .pillars {
        opacity: 0;
        transform: translateY(50px);
        transition-property: opacity, transform;
        transition-duration: 0.7s;
        transition-delay: 0.7s;
    }

    .pillars-container {
        width: 100%;
        display: flex;
        flex-direction: column;

        @media (min-width: 1200px) {
            flex-direction: row;
        }

        .pillar {
            width: 100%;
            border-bottom: 1px solid rgba(#6B123C, 0.2);
            padding-top: 32px;
            padding-bottom: 32px;
            position: relative;
            cursor: pointer;
            transition: all 0.3s;

            @media (min-width: 1200px) {
                padding: 16px 12px;
                border-bottom: none;
                border-radius: 8px 8px 0px 0px;

                &:not(:last-of-type) {
                    &::after {
                        content: "";
                        position: absolute;
                        right: 0px;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 1px;
                        height: 80%;
                        background-color: rgba(#6B123C, 0.2);
                    }
                }
            }



            @media (min-width: 1600px) {
                padding: 16px;
            }

            .pillar-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 24px;
                padding: 0px 8px;

                @media (min-width: 1200px) {
                    padding: 0px;
                    gap: 8px;
                }

                @media (min-width: 1200px) {
                    gap: 16px;
                }

                .pillar-header-content {
                    display: flex;
                    align-items: center;
                    gap: 16px;
                }

                img {
                    width: 58px;
                    height: 58px;
                    object-fit: contain;

                    @media (min-width: 1200px) {
                        width: 40px;
                        height: 40px;
                    }
                }

                h4 {
                    font-family: Setimo-Bold;
                    font-size: 22px;
                    line-height: 26px;
                    color: #6B123C;
                    transition: all 0.3s;

                    @media (min-width: 1200px) {
                        font-size: 15px;
                        line-height: 18px;
                    }


                    @media (min-width: 1500px) {
                        font-size: 20px;
                        line-height: 24px;

                    }
                }

                svg {
                    width: 15px;
                    transition: all 0.3s;

                    @media (min-width: 1200px) {
                        display: none;
                    }
                }
            }

            .pillar-content {
                width: 100%;
                max-height: 0px;
                padding-top: 0px;
                opacity: 0;
                overflow: hidden;
                transition: all 0.3s;

                @media (min-width: 1200px) {
                    display: none;
                }

                ul {
                    width: 100%;
                    list-style: none;
                    display: flex;
                    flex-direction: column;
                    gap: 16px;

                    a {
                        font-family: Georgia;
                        font-size: 24px;
                        color: #6B123C;
                        text-decoration: none;
                    }
                }
            }

            &.open {
                @media (max-width: 1200px) {
                    .pillar-header svg {
                        transform: rotate(180deg);
                    }

                    .pillar-content {
                        max-height: 400px;
                        padding-top: 32px;
                        opacity: 1;
                    }
                }


            }

            &:last-of-type {
                border-bottom: none;
            }
        }
    }

    .pillars {
        padding: 52px 32px;
        background-color: #EFEAE6;

        @media (min-width: 1024px) {
            padding: 52px 40px;
        }

        @media (min-width: 1200px) {
            padding: 52px 90px;
        }

        @media (min-width: 1400px) {
            padding-left: calc((100% - 1200px)/2);
            padding-right: calc((100% - 1200px)/2);
        }

        @media (min-width: 1900px) {
            padding-left: calc((100% - 1500px)/2);
            padding-right: calc((100% - 1500px)/2);
        }



        h3 {
            font-family: Georgia;
            font-size: 24px;
            line-height: 32px;
            color: #6B123C;
            margin-bottom: 32px;

            @media (min-width: 768px) {
                br {
                    display: none;
                }
            }
        }


    }
}

.desk-pillars-modal {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0px;
    top: 0px;
    transition: all 0.3s;
    z-index: 100;
    opacity: 0;
    pointer-events: none;
    user-select: none;

    @media (max-width: 768px) {
        display: none;
    }

    &.show {
        opacity: 1;
        pointer-events: all;
    }

    .bg {
        width: 100%;
        height: 100%;
        background-color: rgba(#000, 0.8);
    }

    .desk-pillar-modal {
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        padding: 32px;
        background-color: #fff;
        transition: all 0.3s;
        pointer-events: none;

        &.show {
            top: 50%;
            opacity: 1;
            pointer-events: all;
        }

        .row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 24px;

            h6 {
                font-family: Setimo-Bold;
                font-size: 18px;
                color: #4a4a4a;
            }

            .close-btn {
                width: 32px;
                height: 32px;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        ul {
            width: 100%;
            height: 100%;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: 1fr;
            grid-column-gap: 32px;
            grid-row-gap: 16px;


            li {
                font-family: Georgia;
                font-size: 16px;

                a {
                    font-family: Georgia;
                    font-size: 18px;
                    color: #000;
                    text-decoration: none;
                    transition: all 0.3s;

                    &:hover {
                        color: #f47f04;
                    }

                }

            }
        }
    }
}