.our-expertise {
    width: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
    padding: 64px 32px;
    background-color: #FFFFFF;

    &.is-visible {

        .content-container h2,
        .content-container .content-container2 {
            opacity: 1;
            transform: translateX(0);
        }
    }

    @media (min-width:1024px) {
        padding-left: 40px;
        padding-right: 40px;
    }

    @media (min-width:1200px) {
        padding-left: 90px;
        padding-right: 90px;
        padding-top: 112px;
        padding-bottom: 112px;
    }

    @media (min-width: 1400px) {
        padding-left: calc((100% - 1200px) / 2);
        padding-right: calc((100% - 1200px) / 2);
    }

    @media (min-width: 1900px) {
        padding-left: calc((100% - 1500px) / 2);
        padding-right: calc((100% - 1500px) / 2);
    }

    h3 {
        font-family: Setimo-Bold;
        color: #F47F04;
        font-size: 15px;
        text-transform: uppercase;
        letter-spacing: 5px;
        margin-bottom: 24px;
    }


    .content-container {
        display: flex;
        flex-direction: column;
        gap: 32px;

        @media (min-width: 768px) {
            flex-direction: row;
            justify-content: space-between;
        }

        @media (min-width: 1024px) {
            gap: 98px;
        }

        h2 {
            font-family: Setimo-Regular;
            font-size: 26px;
            line-height: 38px;
            color: #6B123C;
            text-transform: uppercase;

            opacity: 0;
            transform: translateX(-50px);
            transition-property: opacity, transform;
            transition-duration: 0.7s;
            transition-delay: 0.2s;

            @media (min-width: 1024px) {
                font-size: 32px;
                line-height: 42px;
                max-width: 346px;
            }
        }

        .content-container2 {
            display: flex;
            flex-direction: column;
            gap: 32px;

            opacity: 0;
            transform: translateX(50px);
            transition-property: opacity, transform;
            transition-duration: 0.7s;
            transition-delay: 0.2s;

            .text-container {
                display: flex;
                flex-direction: column;
                gap: 16px;

                p {
                    font-family: Georgia;
                    color: #876C5A;
                    font-size: 16px;
                    line-height: 28px;
                    opacity: 0.7;

                    @media (min-width: 1024px) {
                        font-size: 18px;
                        line-height: 30px;
                    }
                }
            }



            .dropdown {
                width: 100%;
                padding-bottom: 24px;
                border-bottom: 1px solid rgba(#6B123C, 0.2);
                cursor: pointer;

                .dropdown-header {
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    cursor: pointer;

                    span {
                        font-family: Setimo-Bold;
                        font-size: 14px;
                        line-height: 20px;
                        color: #6B123C;
                        letter-spacing: 2px;
                        text-transform: uppercase;

                        @media (min-width: 1024px) {
                            font-size: 15px;
                        }
                    }

                    svg {
                        width: 15px;
                        transition: all 0.3s;
                    }
                }

                .dropdown-content {
                    width: 100%;
                    max-height: 0px;
                    transition: all 0.3s;
                    overflow: hidden;

                    ul {
                        list-style: none;
                        display: flex;
                        flex-direction: column;
                        gap: 8px;

                        @media (min-width: 1200px) {
                            display: grid;
                            grid-template-columns: repeat(2, 1fr);
                        }

                        li {
                            font-family: Georgia;
                            font-size: 18px;
                            line-height: 20px;
                            color: #876C5A;

                            @media (min-width: 1024px) {
                                font-size: 18px;
                                line-height: 30px;
                            }
                        }
                    }
                }

                &.open {
                    .dropdown-header svg {
                        transform: rotate(180deg);
                    }

                    .dropdown-content {
                        max-height: 1000px;
                        transition: all 0.3s;
                        padding-top: 16px;
                    }
                }
            }
        }


    }
}