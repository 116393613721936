.banner {
    width: 100%;
    height: 100dvh;
    position: relative;
    user-select: none;

    &.is-visible {
        .banner-item {
            .content {
                h2 {
                    opacity: 1;
                    transform: translateY(0);
                }

                h1 {
                    opacity: 1;
                    transform: translateY(0);
                }

                p {
                    opacity: 1;
                    transform: translateY(0);
                }

                a {
                    opacity: 1;
                    transform: translateY(0);
                }
            }

            img {
                transform: scale(1);
            }
        }
    }

    .swiper-container {
        height: 100% !important;

        .swiper-slide {
            overflow: hidden !important;
        }
    }

    .banner-item {
        width: 100%;
        height: 100%;
        position: relative;

        .content {
            position: absolute;
            top: 180px;
            left: 30px;
            width: calc(100% - 64px);
            display: flex;
            flex-direction: column;
            gap: 16px;
            margin: 0 auto;
            z-index: 2;

            @media (min-width: 768px) {
                max-width: 960px;
                top: 43%;
                transform: translateY(-50%);
            }

            @media (min-width:1024px) {
                left: 40px;
            }

            @media (min-width:1200px) {
                left: 90px;
            }

            @media (min-width: 1400px) {
                left: calc((100% - 1200px) / 2);
            }

            @media (min-width: 1900px) {
                left: calc((100% - 1500px) / 2);
            }

            h2 {
                font-family: Setimo-Bold;
                color: #F47F04;
                font-size: 15px;
                text-transform: uppercase;
                letter-spacing: 5px;

                opacity: 0;
                transform: translateY(20px);
                transition-property: opacity, transform;
                transition-delay: 0.1s;
                transition-duration: 0.5s;
            }

            h1 {
                font-family: Setimo-Regular;
                color: #fff;
                font-size: 36px;
                text-transform: uppercase;
                letter-spacing: -1px;
                line-height: 44px;

                opacity: 0;
                transform: translateY(20px);
                transition-property: opacity, transform;
                transition-delay: 0.2s;
                transition-duration: 0.5s;

                @media (min-width: 768px) {
                    font-size: 60px;
                    line-height: 68px;
                }
            }

            p {
                display: none;
                font-family: Georgia;
                color: #fff;
                font-size: 18px;
                line-height: 28px;
                max-width: 560px;

                opacity: 0;
                transform: translateY(20px);
                transition-property: opacity, transform;
                transition-delay: 0.3s;
                transition-duration: 0.5s;

                @media (min-width: 768px) {
                    display: block;
                }
            }

            a {
                display: flex;
                align-items: center;
                gap: 14px;
                font-family: Setimo-Bold;
                color: #fff;
                text-decoration: none;
                font-size: 14px;
                text-transform: uppercase;
                letter-spacing: 2px;

                padding: 16px;
                width: fit-content;
                position: relative;
                // bottom: 16px;
                right: 16px;

                opacity: 0;
                transform: translateY(20px);
                transition-property: opacity, transform;
                transition-delay: 0.4s;
                transition-duration: 0.5s;

                @media (min-width: 768px) {
                    &:hover {
                        svg {
                            transform: translateX(8px);
                        }
                    }
                }
            }

            svg {
                width: 24px;
                transition: all 0.3s;
            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            user-select: none;
            pointer-events: none;
            position: absolute;
            top: 0px;
            left: 0px;
            z-index: 1;


            transform: scale(1.1);
            transition-property: transform;
            transition-delay: 0.4s;
            transition-duration: 0.5s;
        }


    }

    .controls {
        position: absolute;
        left: 32px;
        bottom: 90px;
        display: flex;
        align-items: center;
        gap: 48px;
        z-index: 2;

        @media (min-width: 768px) {
            bottom: 190px;
        }

        @media (min-width:1024px) {
            left: 40px;
        }

        @media (min-width:1200px) {
            left: 90px;
        }

        @media (min-width: 1400px) {
            left: calc((100% - 1200px) / 2);
        }

        @media (min-width: 1900px) {
            left: calc((100% - 1500px) / 2);
        }

        .controll-btns {
            display: flex;
            align-items: center;
            gap: 16px;

            .controll-btn {
                padding: 0px;
                border: none;
                width: 40px;
                height: 40px;
                background-color: #6B123C;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all 0.3s;

                path {
                    transition: stroke 0.3s;
                }

                &.swiper-button-disabled {
                    background-color: #fff;

                    path {
                        stroke: rgba(0, 0, 0, 0.5);
                    }
                }

                @media (min-width: 768px) {
                    cursor: pointer;
                }
            }

        }

        .pagination {
            display: flex;
            align-items: center;
            gap: 16px;

            .current-page span,
            .total {
                font-family: Setimo-Bold;
                color: #fff;
                font-size: 13px;
                text-transform: uppercase;
            }

            .divider {
                width: 2px;
                height: 28px;
                background-color: #6B123C;
                transform: rotate(25deg);
            }

        }
    }
}