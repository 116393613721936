.history {
    .history-slides {
        width: 100%;
        height: 100dvh;
        position: relative;

        @media (min-width: 768px) {
            &:after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                width: 150px;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.7);
                z-index: 2;
            }
        }


        @media (min-width: 1024px) {
            &:after {
                width: 134px;
            }
        }

        @media (min-width: 1200px) {
            &:after {
                width: 184px;
            }
        }

        @media (min-width: 1400px) {
            &:after {
                width: calc(((100% - 1200px) / 2) + 94px)
            }
        }

        @media (min-width: 1900px) {
            &:after {
                width: calc(((100% - 1500px) / 2) + 94px)
            }
        }

        .main-swiper-container {
            width: 100%;
            height: 100%;
            background-color: #111;

            .swiper-container {
                width: 100%;
                height: 100%;
            }

            .slide-item {
                width: 100%;
                height: 100%;
                position: relative;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    position: relative;
                    z-index: 1;
                    filter: brightness(50%)
                }

                .content {
                    position: absolute;
                    width: 290px;
                    bottom: 0px;
                    left: 34px;
                    opacity: 0;
                    transition: 2s cubic-bezier(0.68, -0.4, 0.27, 1.34) 0.2s;
                    transition-delay: 200ms;
                    z-index: 2;

                    @media (min-width: 768px) {
                        width: 400px;
                        left: 52px;
                    }

                    @media (min-width: 1024px) {
                        left: 40px;
                    }

                    @media (min-width: 1200px) {
                        left: 90px;
                    }

                    @media (min-width: 1400px) {
                        left: calc((100% - 1200px) / 2)
                    }

                    @media (min-width: 1900px) {
                        left: calc((100% - 1500px) / 2);
                    }

                    h1 {
                        font-family: 'Setimo-Regular';
                        font-size: 70px;
                        font-weight: 100;
                        color: #fff;
                        letter-spacing: -1px;
                        position: absolute;
                        top: -130px;
                        display: none;

                        @media (min-width: 768px) {
                            display: block;
                        }
                    }

                    h2 {
                        font-family: 'Setimo-Regular';
                        font-size: 30px;
                        color: #d5c7ba;
                        font-weight: 100;
                        margin-bottom: 22px;
                        text-transform: uppercase;
                    }

                    p {
                        font-family: 'Georgia';
                        font-size: 20px;
                        color: #fff;
                        line-height: 30px;
                        font-style: italic;
                    }
                }
            }

            .swiper-slide-active .slide-item {
                .content {
                    opacity: 1;
                    bottom: 80px;

                    &::after {
                        content: '';
                        position: absolute;
                        top: -24px;
                        left: 0;
                        width: 45px;
                        height: 4px;
                        background-color: #f47f04;
                    }

                    @media (min-width: 768px) {
                        bottom: 160px;
                    }
                }
            }
        }

        .timeline-swiper-container {
            width: 100%;
            height: 50px;
            z-index: 5;
            position: absolute;
            top: 150px;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            user-select: none;

            @media (min-width: 768px) {
                width: 120px;
                height: 100%;
                top: 0;
                // right: 52px;
                right: 30px;
                left: auto;
                flex-direction: column;
            }

            @media (min-width: 1024px) {
                // right: 40px;
                right: 14px;
            }

            @media (min-width: 1200px) {
                // right: 90px;
                right: 64px;
            }

            @media (min-width: 1400px) {
                // right: calc((100% - 1200px) / 2)
                right: calc(((100% - 1200px) / 2) - 26px);
            }

            @media (min-width: 1900px) {
                right: calc(((100% - 1500px) / 2) - 26px);
            }



            .swiper-btn {
                width: 24px;
                height: 24px;
                cursor: pointer;

                svg {
                    width: 100%;
                    height: 100%;
                }

                &.disabled {
                    opacity: 0.5;
                    cursor: default;
                }

                @media (min-width: 768px) {
                    transform: rotate(90deg);
                }
            }

            .swiper-container {
                width: 60%;
                height: 100%;
                margin: 0px;

                @media (min-width: 768px) {
                    width: 100%;
                    height: 50%;
                }
            }

            .swiper-slide {
                user-select: none;

                .timeline-item {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    font-family: 'Setimo-Regular';
                    font-size: 34px;
                    color: #f47f04;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    user-select: none;


                    @media (min-width: 768px) {
                        font-size: 26px;
                        color: #fff;

                        &.active {
                            &:before {
                                content: '';
                                position: absolute;
                                top: 25%;
                                left: 0;
                                width: 6px;
                                height: 50%;
                                background-color: #f47f04;
                            }
                        }
                    }

                }
            }

        }

    }
}