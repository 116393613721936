#footer {
    padding: 64px 32px 92px 32px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    position: relative;

    @media (min-width: 1024px) {
        padding-left: 40px;
        padding-right: 40px;
    }

    @media (min-width: 1200px) {
        padding-left: 90px;
        padding-right: 90px;
    }

    @media (min-width: 1400px) {
        padding-left: calc((100% - 1200px) / 2);
        padding-right: calc((100% - 1200px) / 2);
    }

    @media (min-width: 1900px) {
        padding-left: calc((100% - 1500px) / 2);
        padding-right: calc((100% - 1500px) / 2);
    }

    .logo-row {
        display: flex;
        flex-direction: column;
        gap: 32px;
        margin-bottom: 64px;

        .logo {
            width: fit-content;
            height: 58px;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        @media (min-width: 768px) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
    }


    .country {
        font-family: Setimo-Bold;
        font-size: 18px;
        letter-spacing: 2.4px;
        color: #F47F04;
        text-transform: uppercase;
        margin-bottom: 24px;
    }

    .state {
        font-family: Setimo-Bold;
        font-size: 15px;
        letter-spacing: 2px;
        color: #6B123C;
        text-transform: uppercase;
    }

    .address {


        strong {
            font-family: Setimo-Bold;
            font-size: 12px;
            letter-spacing: 1.2px;
            color: #000000;
            text-transform: uppercase;
            margin-bottom: 14px;
            display: block;
        }

        p,
        a {
            font-family: Setimo-Regular;
            font-size: 14px;
            line-height: 25px;
            color: #000000;
        }

        a {
            text-decoration: none;
            color: #F47F04;
        }
    }

    .mob-addresses {
        @media (min-width: 1024px) {
            display: none;
        }

        display: flex;
        flex-direction: column;
        gap: 56px;
        width: 100%;

        .country-adresses {
            .state-addresses {
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                padding: 40px 32px;
                position: relative;
                left: -32px;
                width: calc(100% + 64px);
                background-color: #EFEAE6;
                transition: all 0.3s;


                .header {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    svg {
                        width: 15px;
                        transition: all 0.3s;
                    }
                }

                .content {
                    max-height: 0px;
                    transition: all 0.3s;
                    overflow: hidden;

                    .address {
                        &:not(:last-of-type) {
                            margin-bottom: 24px;
                        }
                    }
                }

                &.open {
                    background-color: transparent;

                    .header svg {
                        transform: rotate(180deg);
                    }

                    .content {
                        max-height: 800px;
                        padding-top: 24px;
                    }
                }

                &:not(:last-of-type) {
                    margin-bottom: 4px;
                }
            }
        }

        .no-margin {
            margin-bottom: 0px;
        }
    }

    .desk-addresses {
        @media (max-width: 1023px) {
            display: none;
        }

        display: flex;
        flex-direction: column;
        gap: 70px;
        padding-bottom: 48px;

        .country-adresses {
            .states-addresses {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                grid-template-rows: 1fr;

                .state-addresses {
                    display: flex;
                    flex-direction: column;
                    gap: 32px;
                    padding-left: 60px;
                    padding-right: 60px;
                    position: relative;

                    &:nth-of-type(1) {
                        padding-left: 0px;
                        padding-right: 60px;
                    }

                    &:nth-of-type(4) {
                        padding-left: 60px;
                        padding-right: 0px;
                    }

                    &:not(:last-of-type) {
                        &:after {
                            content: "";
                            position: absolute;
                            width: 1px;
                            height: 80%;
                            background-color: rgba(#CFC4BB, 0.4);
                            right: 0px;
                            bottom: 0px;
                        }
                    }

                    .address {

                        strong {
                            margin-top: 20px;
                        }
                    }
                }
            }
        }


    }

    .contact {
        padding-top: 48px;
        padding-bottom: 24px;
        display: flex;
        flex-direction: column;
        gap: 32px;
        border-top: 1px solid rgba(107, 18, 60, 0.2);

        @media (min-width: 768px) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        .btns {
            display: flex;
            flex-direction: column;
            gap: 32px;

            @media (min-width: 1024px) {
                flex-direction: row;
            }

            .contact-btn {
                flex: 1;
                width: 100%;
                border: 2px solid #F47F04;

                span {
                    color: #F47F04;
                }


                @media (min-width: 768px) {

                    &:after {
                        background-color: #F47F04;
                    }

                    &:hover {
                        span {
                            color: #fff;
                        }
                    }
                }

            }
        }

        .privacy {
            display: flex;
            gap: 24px;
            justify-content: space-between;


            a {
                color: #f47f04;
                font-family: "Setimo-Regular";
                font-size: 16px;
            }
        }
    }

    .legal {
        display: flex;
        flex-direction: column;
        gap: 8px;

        @media (min-width: 768px) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        .copyright {
            font-family: Georgia;
            font-size: 14px;
            font-style: italic;
            line-height: 1.5;
            color: #876C5A;
        }

        .register {
            font-family: "Setimo-Regular";
            font-size: 12px;
            line-height: 20px;
            color: #876C5A;
            display: none;

            @media (min-width: 768px) {
                text-align: right;
            }
        }


    }

    .social-row {
        display: flex;
        gap: 32px;

        @media (min-width: 768px) {
            gap: 24px;
        }


        a {
            display: block;
            width: 32px;
            height: 32px;

            @media (min-width: 768px) {
                width: 28px;
                height: 28px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}