.home-news {
    position: relative;
    width: 100%;
    margin-top: -52px;
    z-index: 2;
    overflow: hidden;

    &.is-visible {
        .home-news-grid {
            article {
                opacity: 1 !important;
            }
        }
    }

    @media (min-width: 768px) {
        margin-top: -130px;
    }

    .home-news-header {
        width: 80%;
        height: 52px;
        background-color: #EFEAE6;
        display: flex;
        align-items: center;

        @media (min-width: 768px) {
            width: calc(100% - 32px);
            height: 130px;
        }

        @media (min-width:1024px) {
            width: calc(100% - 40px);
        }

        @media (min-width:1200px) {
            width: calc(100% - 90px);
        }

        @media (min-width: 1400px) {
            width: calc(100% - (100% - 1200px)/2);
        }

        @media (min-width: 1900px) {
            width: calc(100% - (100% - 1500px)/2);
        }

        .home-news-header-content {
            position: relative;
            width: 100%;
            top: 75px;
            left: 32px;
            display: flex;
            flex-direction: column;
            gap: 32px;

            @media (min-width: 768px) {
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                position: static;
                padding-left: 32px;
                padding-right: 54px;
            }

            @media (min-width:1024px) {
                padding-left: 40px;
            }

            @media (min-width:1200px) {
                padding-left: 90px;
            }

            @media (min-width: 1400px) {
                padding-left: calc(100% - 1200px);
            }

            @media (min-width: 1900px) {
                padding-left: calc(100% - 1500px);
            }

            h3 {
                font-family: Setimo-Bold;
                font-size: 18px;
                color: #6B123C;
                letter-spacing: 4px;
                text-transform: uppercase;
            }

            .filter-container {
                width: calc(100vw - 64px);
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 32px;

                @media (min-width: 768px) {
                    width: 400px;
                }

                .filter-container-inputs {
                    flex: 1;
                    height: 50px;
                    position: relative;

                    .filter-container-inputs-slider {
                        width: 100%;
                        height: 100%;
                        position: relative;
                        overflow: hidden;

                        .home-news-select {
                            width: 100%;
                            height: 48px;
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            transition: all 0.3s;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            border-bottom: 2px solid rgba(#6B123C, 0.4);
                            cursor: pointer;
                            opacity: 1;
                            user-select: none;

                            span {
                                font-family: Setimo-Regular;
                                font-size: 14px;
                                color: #6B123C;
                                letter-spacing: 2px;
                                text-transform: uppercase;
                            }

                            svg {
                                width: 15px;
                                transition: all 0.3s;

                                &.rotate {
                                    transform: rotate(180deg);
                                }
                            }
                        }

                        .home-news-search-input {
                            width: 100%;
                            height: 48px;
                            position: absolute;
                            left: 0px;
                            top: 48px;
                            transition: all 0.3s;
                            border-bottom: 2px solid rgba(#6B123C, 0.4);
                            opacity: 0;
                            position: relative;
                            padding-right: 40px;
                            box-sizing: border-box;



                            input {
                                width: 100%;
                                height: 100%;
                                padding: 0px;
                                border: none;
                                background-color: transparent;
                                font-family: Setimo-Regular;
                                font-size: 18px;
                                color: #876C5A;
                                outline: none;

                                &::placeholder {
                                    font-family: Setimo-Regular;
                                    font-size: 18px;
                                    color: rgba(#6B123C, 0.8);
                                    letter-spacing: 2px;
                                }
                            }

                            .close-search-btn {
                                position: absolute;
                                top: 0px;
                                right: 0px;
                                width: 40px;
                                height: 100%;
                                background-color: #EFEAE6;
                                cursor: pointer;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                        }

                        &.search-selected {
                            .home-news-select {
                                top: -48px;
                                opacity: 0;
                            }

                            .home-news-search-input {
                                top: 0px;
                                opacity: 1;
                            }
                        }
                    }

                    .home-news-select-items {
                        position: absolute;
                        top: 48px;
                        left: 0px;
                        width: calc(100%);
                        padding: 0px 0px;
                        background-color: #fff;
                        overflow: hidden;
                        list-style: none;
                        border-top: 2px solid #6B123C;
                        max-height: 0px;
                        transition: all 0.3s ease;
                        pointer-events: none;
                        opacity: 0;
                        z-index: 5;
                        box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.2);
                        user-select: none;

                        span {
                            display: none;
                        }

                        &.open {
                            max-height: 800px;
                            padding: 8px 0px;
                            pointer-events: all;
                            opacity: 1;
                        }

                        li {
                            padding: 16px;
                            cursor: pointer;
                            font-family: Setimo-Regular;
                            font-size: 14px;
                            color: #6B123C;
                            letter-spacing: 2px;
                            text-transform: uppercase;

                            user-select: none;

                            &:hover {
                                background-color: #6B123C;
                                color: #fff;
                            }
                        }
                    }
                }

                .home-news-search-btn {
                    width: 48px;
                    height: 48px;
                    border-radius: 100%;
                    background-color: #fff;
                    cursor: pointer;
                    padding: 0px;
                    border: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: all 0.3s;

                    svg {
                        width: 16px;

                        path {
                            transition: all 0.3s;
                        }

                    }

                    &.search-selected {
                        background-color: #6B123C;

                        path {
                            fill: #fff;
                        }
                    }
                }
            }
        }



    }

    .home-news-content {
        width: 100%;
        background-color: #EFEAE6;
        box-sizing: border-box;
        padding-bottom: 64px;

        @media (min-width: 768px) {
            padding-bottom: 102px;
        }

        @media (min-width:1024px) {
            padding-left: 40px;
            padding-right: 40px;
        }

        @media (min-width:1200px) {
            padding-left: 90px;
            padding-right: 90px;
        }

        @media (min-width: 1400px) {
            padding-left: 0px;
            padding-right: 0px;
        }

        .home-news-grid {
            padding-top: 140px;

            @media (min-width: 1400px) {
                max-width: 1200px;
                margin: 0px auto;
            }

            @media (min-width: 1900px) {
                max-width: 1500px;
            }

            article {
                width: 100%;
                position: relative;
                overflow: hidden;

                opacity: 0;
                transition-property: opacity;
                transition-duration: 0.4s;

                @for $i from 1 to 8 {
                    &:nth-of-type(#{$i}) {
                        transition-delay: $i * 0.1s;
                    }
                }

                a {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    text-decoration: none;
                    position: relative;
                }

                &:hover {
                    img {
                        transform: scale(1.1);
                    }
                }

                .image-container {
                    overflow: hidden;
                    flex-shrink: 0;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        transition: all 0.3s;
                    }
                }

                .info-container {
                    box-sizing: border-box;
                }

                .main-info {
                    display: flex;
                    flex-direction: column;
                    gap: 24px;
                }

                &:nth-of-type(odd) {
                    .info-container {
                        background-color: #F5F2F0 !important;
                    }
                }

                &:nth-of-type(even) {
                    .info-container {
                        background-color: #FFFFFF !important;
                    }
                }

                &:nth-of-type(1) {
                    .image-container {
                        width: 100%;
                        height: 100%;
                    }

                    .info-container {
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        width: 100%;
                        height: 100%;
                        z-index: 2;
                        background-color: transparent !important;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        .category {
                            display: block;
                            font-family: Setimo-Bold;
                            color: #fff;
                            text-transform: uppercase;
                        }

                        .main-info {
                            display: flex;
                            flex-direction: column;
                            gap: 24px;
                        }

                        .date {
                            display: flex;
                            gap: 8px;
                            align-items: center;

                            span {
                                font-family: Setimo-Regular;
                                color: #fff;
                                font-size: 14px;
                                letter-spacing: 1.4px;
                            }

                            .divider {
                                width: 2px;
                                height: 20px;
                                background-color: #fff;
                                opacity: 0.5;
                            }
                        }

                        h3 {
                            font-family: Setimo-Regular;
                            color: #fff;
                        }

                        p {
                            display: none;
                        }
                    }
                }

                &:not(:nth-of-type(1)) {
                    .info-container {

                        .category,
                        .date {
                            display: none;
                        }

                        h3 {
                            font-family: Setimo-Regular;
                            color: #6B123C;
                        }
                    }
                }
            }


            @media (min-width: 768px) {
                padding-top: 0px;
            }

            @media (max-width: 1023px) {
                display: flex;
                flex-direction: column;

                article {
                    &:nth-of-type(1) {
                        height: 100vw;

                        .info-container {
                            padding: 32px;

                            .category {
                                font-size: 14px;
                                letter-spacing: 4.2px;
                            }

                            h3 {
                                font-size: 18px;
                                line-height: 26px;
                                height: 82px;
                            }
                        }
                    }

                    &:not(:nth-of-type(1)) {
                        height: 130px;

                        a {
                            flex-direction: row;
                        }

                        .image-container {
                            width: 150px;
                            height: 100%;
                        }

                        .info-container {
                            box-sizing: border-box;
                            width: calc(100% - 155px);
                            height: 100%;
                            background-color: #fff;
                            padding: 16px 24px;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            position: relative;

                            h3 {
                                font-size: 16px;
                                line-height: 24px;
                            }

                            p {
                                display: none;
                            }
                        }
                    }

                    &:nth-of-type(6),
                    &:nth-of-type(7) {
                        display: none;
                    }
                }
            }

            @media (min-width: 1024px) {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                grid-template-rows: repeat(4, 1fr);
                padding-top: 0px;

                article {
                    &:hover {
                        img {
                            transform: scale(1.1);
                        }
                    }

                    &:nth-of-type(1) {
                        grid-area: 1 / 1 / 3 / 3;
                        height: 100%;

                        .info-container {
                            padding: 40px;
                            align-items: flex-end;

                            &:before {
                                content: "";
                                position: absolute;
                                left: 0px;
                                bottom: 0px;
                                width: 100%;
                                height: 70%;
                                background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
                                z-index: 1;
                            }

                            .category {
                                font-size: 14px;
                                letter-spacing: 10px;
                            }

                            .main-info {
                                position: relative;
                                z-index: 10;
                            }

                            .date {
                                justify-content: flex-end;
                            }

                            h3 {
                                font-size: 20px;
                                line-height: 28px;
                                height: 124px;
                                text-align: end;
                            }
                        }
                    }

                    &:not(:nth-of-type(1)) {
                        width: 100%;
                        height: 100%;

                        .info-container {
                            box-sizing: border-box;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            padding: 24px 40px;
                            flex: 1;

                            .main-info {
                                gap: 16px;
                            }

                            h3 {
                                font-size: 20px;
                                line-height: 24px;
                            }

                            p {
                                font-family: Georgia;
                                font-size: 16px;
                                line-height: 22px;
                                color: #876C5A;

                                max-height: 120px;
                                overflow: hidden;
                            }
                        }
                    }


                    &:nth-of-type(2) {
                        grid-area: 1 / 3 / 2 / 5;
                    }

                    &:nth-of-type(3) {
                        grid-area: 2 / 3 / 3 / 5;
                    }

                    &:nth-of-type(4) {
                        grid-area: 3 / 1 / 5 / 2;
                    }

                    &:nth-of-type(5) {
                        grid-area: 3 / 2 / 5 / 3;
                    }

                    &:nth-of-type(6) {
                        grid-area: 3 / 3 / 5 / 4;
                    }

                    &:nth-of-type(7) {
                        grid-area: 3 / 4 / 5 / 5;
                    }

                    &:nth-of-type(n+2):nth-of-type(-n+3) {
                        a {
                            flex-direction: row;
                        }

                        .image-container {
                            width: 50%;
                            height: 100%;
                        }
                    }

                    &:nth-of-type(n+4):nth-of-type(-n+7) {
                        a {
                            flex-direction: column;
                        }

                        .image-container {
                            width: 100%;
                            height: 50%;
                        }
                    }
                }
            }

        }

        .news-more-btn {
            margin-top: 64px;
            margin-left: 32px;

            @media (min-width: 1024px) {
                margin-left: 0px;
            }

            @media (min-width: 1400px) {
                margin-left: calc((100% - 1200px)/2);
            }

            @media (min-width: 1900px) {
                margin-left: calc((100% - 1500px)/2);
            }
        }
    }
}